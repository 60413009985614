import { StateToken, createSelector } from '@ngxs/store';
import { ReservationViewFocus, TableReservation } from 'src/app/models';

export type TableReservationsStateModel = {
  date: string;
  diner_name: string;
  reservations: TableReservation[];
  dinerSearchReservations: TableReservation[];
  reservationViewFocus: ReservationViewFocus;
};

export const TABLE_RESERVATIONS_STATE_TOKEN =
  new StateToken<TableReservationsStateModel>('reservations');
