import { createPropertySelectors, createSelector } from '@ngxs/store';
import _ from 'lodash';

import { MenuStateModel } from './menu.state.model';
import { MenuState } from './menu.state';
import { MenuStateHelper } from 'src/app/store/menu/menu.state.helper';
import { MenuHelper } from 'src/app/helpers';
import { BaseMenuItem, MenuItem, MenuItemType } from 'src/app/models';
import { DinersSelectors } from 'src/app/store/diners/diners.selectors';
import { LocationSelectors } from 'src/app/store/location/location.selectors';

export class MenuSelectors {
  static slices = createPropertySelectors<MenuStateModel>(MenuState);

  static menu_selected_date_meal_id = createSelector(
    [MenuSelectors.slices.menu_selected_date_meal_id],
    (menu_selected_date_meal_id) => menu_selected_date_meal_id
  );

  static isMacroGridsLoading = createSelector(
    [MenuSelectors.slices.macroGrids],
    (macroGrids) => macroGrids.isProcessing
  );

  static isMacroGridsLoaded = createSelector(
    [MenuSelectors.slices.macroGrids],
    (macroGrids) => macroGrids.isLoaded
  );

  static getMacroGrids = createSelector(
    [MenuSelectors.slices.macroGrids],
    (macroGrids) => macroGrids.data
  );

  static isNoAssignedMacroGrids = createSelector(
    [MenuSelectors.isMacroGridsLoaded, MenuSelectors.getMacroGrids],
    (isLoaded, data) => isLoaded && !data.length
  );

  static menu = createSelector([MenuSelectors.slices.menu], (menu) => menu);

  static menuCategories = createSelector(
    [MenuSelectors.menu],
    (menu) => menu.categories
  );

  static quantity_restrictions = createSelector(
    [MenuSelectors.slices.quantity_restrictions],
    (quantity_restrictions) => quantity_restrictions
  );

  private static menu_date = createSelector(
    [MenuSelectors.slices.menu_date],
    (menu_date) => menu_date
  );

  static selectedQuantityRestrictions = createSelector(
    [MenuSelectors.quantity_restrictions, MenuSelectors.menu_date],
    (quantity_restrictions, menu_date) => quantity_restrictions[menu_date]
  );

  static getQuantityRestrictionByItemId = createSelector(
    [MenuSelectors.selectedQuantityRestrictions],
    (quantity_restrictions) => (food_id?: number, product_id?: number) =>
      !food_id && !product_id
        ? null
        : quantity_restrictions?.find((i) =>
            food_id ? i.food_id === food_id : i.product_id === product_id
          )
  );

  static getFullPromptSetup = createSelector(
    [
      MenuSelectors.slices.menuItemsWithModifierPrompts,
      MenuSelectors.quantity_restrictions,
      MenuSelectors.menu_selected_date_meal_id
    ],
    (
        menuItemsWithModifierPrompts,
        quantity_restrictions,
        menu_selected_date_meal_id
      ) =>
      (menuItem: BaseMenuItem) => {
        const selectedMenuItem = menuItemsWithModifierPrompts.find(
          (x: MenuItem) =>
            (x.type !== MenuItemType.Product &&
              x.food_id === menuItem.food_id) ||
            (x.type === MenuItemType.Product && x.id === menuItem.id)
        );
        if (_.isEmpty(quantity_restrictions)) {
          return selectedMenuItem;
        }
        return MenuStateHelper.addQuantityRestrictionsToPrompts(
          selectedMenuItem,
          quantity_restrictions[
            MenuHelper.getSelectedMenuDate(menu_selected_date_meal_id)
          ]
        );
      }
  );

  static macro_grid_view = createSelector(
    [MenuSelectors.slices.macro_grid_view],
    (macro_grid_view) => macro_grid_view
  );

  static isModifierGridVisible = createSelector(
    [MenuSelectors.slices.isModifierGridVisible],
    (isModifierGridVisible) => isModifierGridVisible
  );

  static macro_stack = createSelector(
    [MenuSelectors.slices.macro_stack],
    (macro_stack) => macro_stack
  );

  static barcodeProducts = createSelector(
    [MenuSelectors.slices.barcodeProducts],
    (barcodeProducts) => barcodeProducts
  );

  static modifier_macro_stack = createSelector(
    [MenuSelectors.slices.modifier_macro_stack],
    (modifier_macro_stack) => modifier_macro_stack
  );

  static selected_meal_id = createSelector(
    [MenuSelectors.slices.selected_meal_id],
    (selected_meal_id) => selected_meal_id
  );

  static selected_macro_grid = createSelector(
    [MenuSelectors.slices.selected_macro_grid],
    (selected_macro_grid) => selected_macro_grid
  );

  static getIsMacroGridFetching = createSelector(
    [MenuSelectors.selected_macro_grid],
    (selected_macro_grid) => !selected_macro_grid
  );

  static getIsMenuLoading = createSelector(
    [MenuSelectors.menu],
    (menu) => !menu
  );

  static menu_tally = createSelector(
    [MenuSelectors.slices.menu_tally],
    (menu_tally) => menu_tally
  );

  static getAllCategoryItems = createSelector(
    [
      MenuSelectors.menu,
      MenuSelectors.menu_tally,
      DinersSelectors.getSelectedDiner,
      MenuSelectors.selected_meal_id
    ],
    (menu, menu_tally, selectedDiner, meal_id) =>
      MenuHelper.getAllCategoryItems(
        MenuHelper.getAllMenuItems(menu, menu_tally, selectedDiner, meal_id)
      )
  );

  static getMenuItems = createSelector(
    [
      MenuSelectors.selected_macro_grid,
      MenuSelectors.menu,
      MenuSelectors.menu_tally,
      DinersSelectors.getSelectedDiner,
      LocationSelectors.getIsServiceAppointment,
      MenuSelectors.selected_meal_id
    ],
    (
      selected_macro_grid,
      menu,
      menu_tally,
      selectedDiner,
      isServiceAppointment,
      meal_id
    ) =>
      MenuHelper.getMenuItems(
        selected_macro_grid,
        menu,
        menu_tally,
        selectedDiner,
        isServiceAppointment,
        meal_id
      )
  );

  static selected_modifier_macro_grid = createSelector(
    [MenuSelectors.slices.selected_modifier_macro_grid],
    (selected_modifier_macro_grid) => selected_modifier_macro_grid
  );

  static getSelectedMacroGrid = createSelector(
    [
      MenuSelectors.isModifierGridVisible,
      MenuSelectors.selected_modifier_macro_grid,
      MenuSelectors.selected_macro_grid
    ],
    (
      isModifierGridVisible,
      selected_modifier_macro_grid,
      selected_macro_grid
    ) =>
      isModifierGridVisible ? selected_modifier_macro_grid : selected_macro_grid
  );

  static getDisplayMenuItems = createSelector(
    [MenuSelectors.getMenuItems, MenuSelectors.getSelectedMacroGrid],
    (menuItems, selectedMacroGrid) =>
      MenuHelper.getDisplayMenuItems(menuItems, selectedMacroGrid)
  );

  static getCategoriesByDisplayMenuItems = createSelector(
    [MenuSelectors.getDisplayMenuItems, MenuSelectors.menu],
    (displayMenuItems, menu) =>
      MenuHelper.getCategoriesByDisplayMenuItems(displayMenuItems, menu)
  );

  static getCategoryById = createSelector(
    [MenuSelectors.menuCategories],
    (categories) => (id: number) => categories.find((x) => x.id === id)
  );
}
