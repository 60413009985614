import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { State, Action, StateContext, Store, StateToken } from '@ngxs/store';
import { CommonConstant, PageConstant } from 'src/app/constants';
import { produce } from 'immer';

import {
  EnableTakeOutDelivery,
  ExitTakeOutDelivery,
  GetTakeoutDeliveryTickets,
  LockTakeoutTicket,
  OpenTransactionTicketUuid,
  UnlockTakeoutTicket,
  UpdateTakeoutDeliveryFlag,
  UpdateTakeoutDeliveryLocked,
  UpdateTakeoutDeliveryStatus
} from './takeout-delivery.action';
import {
  LocationType,
  TakeOutDeliveryOrder,
  TakeoutOrderStatus
} from 'src/app/models';
import { TicketService } from 'src/app/services';
import { tap } from 'rxjs';
import _ from 'lodash';
import moment from 'moment';
import {
  CleanSelectTicket,
  SelectBackQSTicket
} from '../tickets/tickets.action';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { TICKETS_STATE_TOKEN } from 'src/app/store/tickets/tickets.state';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';

export interface TakeOutDeliveryStateModel {
  enableTakeOutDelivery: boolean;
  tickets: TakeOutDeliveryOrder[];
  quickServiceTicketUuid: string;
  ticketsLoaded: boolean;
  openTransactionTicketUuid: string;
}

export const TAKEOUT_DELIVERY_STATE_TOKEN =
  new StateToken<TakeOutDeliveryStateModel>('takeout_delivery');

@State({
  name: TAKEOUT_DELIVERY_STATE_TOKEN,
  defaults: {
    enableTakeOutDelivery: false,
    tickets: [],
    quickServiceTicketUuid: null,
    ticketsLoaded: false,
    openTransactionTicketUuid: null
  }
})
@Injectable()
export class TakeOutDeliveryState {
  constructor(
    private ticketService: TicketService,
    private navController: NavController,
    private store: Store
  ) {}

  @Action(EnableTakeOutDelivery)
  enableTakeOutDelivery({
    patchState
  }: StateContext<TakeOutDeliveryStateModel>) {
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isQuickService: boolean = locationType === LocationType.QuickService;
    const isDiningRoom: boolean = locationType === LocationType.DiningRoom;

    // allow to back to order with selected ticket for quick service
    let quickServiceTicketUuid;
    if (isQuickService) {
      quickServiceTicketUuid =
        this.store.selectSnapshot(TICKETS_STATE_TOKEN).selectedTicket.data;
    }
    const nextPage = PageConstant.TAKEOUT_DELIVERY_PAGE(isDiningRoom);
    patchState({ enableTakeOutDelivery: true, quickServiceTicketUuid });
    this.navController.navigateRoot(nextPage, {
      animated: true,
      animationDirection:
        nextPage === PageConstant.TAKEOUT_DELIVERY_PAGE(isDiningRoom)
          ? 'forward'
          : 'back'
    });
  }

  @Action(UpdateTakeoutDeliveryFlag)
  updateTakeoutDeliveryFlag(
    { patchState }: StateContext<TakeOutDeliveryStateModel>,
    { enableTakeOutDelivery }: UpdateTakeoutDeliveryFlag
  ) {
    // this method is used to update flag when user switch between dining room, quick service to Go to orders page.
    patchState({ enableTakeOutDelivery });
  }

  @Action(ExitTakeOutDelivery)
  exitTakeOutDelivery({
    getState,
    patchState,
    dispatch
  }: StateContext<TakeOutDeliveryStateModel>) {
    patchState({ enableTakeOutDelivery: false });
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isDiningRoom: boolean = locationType === LocationType.DiningRoom;
    const qsTicketUuid = getState().quickServiceTicketUuid;
    const selectedTicket =
      this.store.selectSnapshot(TICKETS_STATE_TOKEN).selectedTicket.data;

    if (!isDiningRoom && (!selectedTicket || qsTicketUuid !== selectedTicket)) {
      return dispatch([
        new CleanSelectTicket(),
        new SelectBackQSTicket(qsTicketUuid)
      ]);
    }
  }

  @Action(GetTakeoutDeliveryTickets, { cancelUncompleted: true })
  getTakeoutDeliveryTicket({
    patchState
  }: StateContext<TakeOutDeliveryStateModel>) {
    return this.ticketService.getTakeoutTickets().pipe(
      tap((tickets) => {
        const formattedTickets: TakeOutDeliveryOrder[] = tickets.map(
          (ticket: TakeOutDeliveryOrder) => {
            const combinedStr = `${ticket.scheduled_ticket_date} ${
              ticket.scheduled_ticket_time || ''
            }`;
            const scheduledDate = moment(
              combinedStr,
              CommonConstant.MOMENT_DATE_TIME.DATE_TIME
            );
            const freeTextSearch = `${ticket.diner_name || 'Guest'}
            ${scheduledDate.format(
              ticket.scheduled_ticket_time
                ? CommonConstant.MOMENT_DATE_TIME.SORT_DATE_TIME
                : CommonConstant.MOMENT_DATE_TIME.SORT_DATE
            )}
            ${ticket.scheduled_ticket_time ? '' : 'ASAP'}
            ${(ticket.delivery_type || '').replace('_', ' ')}
            ${ticket.delivery_room_location || ''}
            ${(ticket.takeout_order_status || '').replace('_', ' ')}`;
            return <TakeOutDeliveryOrder>{
              ...ticket,
              scheduledDate: scheduledDate.toDate(),
              freeTextSearch
            };
          }
        );
        patchState({
          tickets: _.orderBy(formattedTickets, ['scheduledDate']),
          ticketsLoaded: true
        });
      })
    );
  }

  @Action(UpdateTakeoutDeliveryLocked)
  updateTakeoutDeliveryLocked(
    ctx: StateContext<TakeOutDeliveryStateModel>,
    { data }: UpdateTakeoutDeliveryLocked
  ) {
    ctx.setState(
      produce((draft: TakeOutDeliveryStateModel) => {
        const ticketIndex = draft.tickets?.findIndex(
          (x) => x.device_ticket_uuid === data.device_ticket_uuid
        );

        if (ticketIndex > -1) {
          draft.tickets[ticketIndex].locked = data.locked;
          draft.tickets[ticketIndex].locked_by_operator_id =
            data.locked_by_operator_id;
        }
      })
    );
  }

  @Action(UpdateTakeoutDeliveryStatus)
  updateTakeoutDeliveryStatus(
    { patchState, getState, dispatch }: StateContext<TakeOutDeliveryStateModel>,
    { wsMessage }: UpdateTakeoutDeliveryStatus
  ) {
    switch (wsMessage.takeout_order_status) {
      case TakeoutOrderStatus.COMPLETED: {
        patchState({
          tickets: _.filter(
            getState().tickets,
            (x: TakeOutDeliveryOrder) =>
              x.device_ticket_uuid !== wsMessage.device_ticket_uuid
          )
        });
        break;
      }
      case TakeoutOrderStatus.IN_PRODUCTION:
      case TakeoutOrderStatus.SCHEDULED:
      case TakeoutOrderStatus.PRODUCTION_COMPLETE: {
        if (
          _.find(getState().tickets, {
            device_ticket_uuid: wsMessage.device_ticket_uuid
          })
        ) {
          patchState({
            tickets: _.map(getState().tickets, (x: TakeOutDeliveryOrder) => {
              if (x.device_ticket_uuid === wsMessage.device_ticket_uuid) {
                return {
                  ...x,
                  takeout_order_status: wsMessage.takeout_order_status
                };
              }
              return x;
            })
          });
        } else {
          // fetch data from server
          dispatch(new GetTakeoutDeliveryTickets());
        }
        break;
      }
    }
  }

  @Action(LockTakeoutTicket)
  lockTakeoutTicket(
    { patchState, getState }: StateContext<TakeOutDeliveryStateModel>,
    { ticketId }: LockTakeoutTicket
  ) {
    const operator = this.store.selectSnapshot(APP_STATE_TOKEN).operator;

    patchState({
      tickets: _.map(getState().tickets, (x: TakeOutDeliveryOrder) => {
        if (x.device_ticket_uuid === ticketId) {
          return {
            ...x,
            locked: true,
            locked_by_operator_id: operator.id
          };
        }
        return x;
      })
    });
    return this.ticketService.lockTicket(ticketId, operator.id);
  }

  @Action(UnlockTakeoutTicket)
  unlockTakeoutTicket(
    { patchState, getState }: StateContext<TakeOutDeliveryStateModel>,
    { ticketId }: LockTakeoutTicket
  ) {
    patchState({
      tickets: _.map(getState().tickets, (x: TakeOutDeliveryOrder) => {
        if (x.device_ticket_uuid === ticketId) {
          return {
            ...x,
            locked: false,
            locked_by_operator_id: null
          };
        }
        return x;
      })
    });
    return this.ticketService.unlockTicket(ticketId);
  }

  @Action(OpenTransactionTicketUuid)
  openTransactionTicketUuid(
    { patchState }: StateContext<TakeOutDeliveryStateModel>,
    { ticketUuid }: OpenTransactionTicketUuid
  ) {
    patchState({ openTransactionTicketUuid: ticketUuid });
  }
}
