import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ConnectionStatus, Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeadersInterceptor } from './interceptors/headers.interceptor';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgIdleModule } from '@ng-idle/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxActioncableService } from '@csventures/ngx-actioncable';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { PosManagerMenuModule } from './components/pos-manager/menu/pos-manager.module';
import { RouteReuseStrategy } from '@angular/router';
import { SharedModule } from './components/shared/shared.module';
import { StoreModule } from './store/store.module';
import { TimeoutModal } from './modals/timeout/timeout.modal';
import { UrlInterceptor } from './interceptors/url.interceptor';
import { environment } from 'src/environments/environment';
import { getErrorHandler } from './sentry-error-handler';
import { ConfirmModalModule } from 'src/app/modals/confirm/confirm-modal.module';
import { BlurButtonDirectiveModule } from 'src/app/directives/blur-button/blur-button-directive.module';
import { InputFocusDirectiveModule } from 'src/app/directives/input-focus-directive/input-focus-directive.module';
import { RefundsModalModule } from 'src/app/modals/refunds/refunds-modal.module';
import { OrderSetupModalModule } from 'src/app/components/takeout-and-delivery/order-setup/order-setup-modal.module';
import { ManagerApprovalModalModule } from 'src/app/modals/manager-approval/manager-approval-modal.module';
import { GiftCardPaymentModalModule } from 'src/app/modals/payments/gift-card/gift-card-payment-modal.module';
import { DinerAssignmentModalModule } from 'src/app/modals/ticket/diner-assignment/diner-assignment-modal.module';
import { PatientInformationModalModule } from 'src/app/modals/patient-information/patient-information-modal.module';
import { EditTableModalModule } from 'src/app/modals/dining/edit-table/edit-table-modal.module';
import { TableAssignmentSearchStaffModalModule } from 'src/app/modals/dining/table-assignment-search-staff/table-assignment-search-staff-modal.module';
import { BillToRoomPaymentModalModule } from 'src/app/modals/payments/bill-to-room/bill-to-room-payment-modal.module';
import { GatewayTransactionModalModule } from 'src/app/modals/payments/gateway-transaction/gateway-transaction-modal.module';
import { DepartmentChargeAccountPaymentModalModule } from 'src/app/modals/payments/department-charge-account/department-charge-account-payment-modal.module';
import { PosManagerConfirmModalModule } from 'src/app/modals/pos-manager/modifier-prompts/pos-manager-confirm/pos-manager-confirm-modal.module';
import { ModifierPromptsAssignmentModalModule } from 'src/app/modals/pos-manager/modifier-prompts-assignment/modifier-prompts-assignment-modal.module';
import { AddItemModifierPromptsModalModule } from 'src/app/modals/item-modifier-prompts/add-item-modifier-prompts-modal.module';
import { CustomPriceItemModalModule } from 'src/app/modals/macro-grid/custom-price-item/custom-price-item-modal.module';
import { WeightedItemModalModule } from 'src/app/modals/macro-grid/weighted-item/weighted-item-modal.module';
import { GiftCardItemModalModule } from 'src/app/modals/macro-grid/gift-card-item/gift-card-item-modal.module';
import { OpenTextModifierModalModule } from 'src/app/modals/macro-grid/open-text-modifier/open-text-modifier-modal.module';
import { SelfServiceGatewayTransactionModalModule } from 'src/app/components/self-service/modals/self-service-gateway-transaction/self-service-gateway-transaction-modal.module';
import { SelfServiceInputSecureCodeModalModule } from 'src/app/components/self-service/modals/self-service-input-secure-code/self-service-input-secure-code-modal.module';
import { MenuModule } from 'src/app/components/services-appointment/menu';
import {
  LoadHardwareDetails,
  NetworkStatusChange
} from 'src/app/store/app/app.action';
import { NfcReaderService } from 'src/app/services/nfc-reader/nfc-reader.service';
import { StripeTransactionModalModule } from './modals/payments/stripe-transaction/stripe-transaction-modal.module';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

const MODAL_MODULES = [
  RefundsModalModule,
  OrderSetupModalModule,
  GiftCardPaymentModalModule,
  ManagerApprovalModalModule,
  DinerAssignmentModalModule,
  ConfirmModalModule,
  PatientInformationModalModule,
  EditTableModalModule,
  TableAssignmentSearchStaffModalModule,
  BillToRoomPaymentModalModule,
  GatewayTransactionModalModule,
  DepartmentChargeAccountPaymentModalModule,
  PosManagerConfirmModalModule,
  ModifierPromptsAssignmentModalModule,
  AddItemModifierPromptsModalModule,
  CustomPriceItemModalModule,
  WeightedItemModalModule,
  GiftCardItemModalModule,
  OpenTextModifierModalModule,
  SelfServiceGatewayTransactionModalModule,
  SelfServiceInputSecureCodeModalModule,
  StripeTransactionModalModule
];

const MENU_MODULES = [];

function initializeApp(store: Store, nfc: NfcReaderService) {
  return () =>
    new Promise(async (resolve) => {
      const isAndroid = Capacitor.getPlatform() === 'android';
      let nfcEnabled = false;

      //Some Android devices will crash if NFC is not on the device or disabled, wrapping this logic on the try statement to avoid it
      try {
        //If platform is Android AND NFC is Enabled on the Device
        if (isAndroid) {
          nfcEnabled = (await nfc.isNFCEnabled()).success;
        }
      } catch (error) {
        // Error when the device doesn't support NFC.
        // Catch exception for reducing Sentry issues
      }

      // Do some asynchronous stuff
      const status = await Network.getStatus();
      const deviceInfo = await Device.getInfo();
      const deviceId = await Device.getId();

      store.dispatch([
        new NetworkStatusChange(status.connected),
        new LoadHardwareDetails(deviceInfo, deviceId, nfcEnabled)
      ]);

      await Network.addListener(
        'networkStatusChange',
        (network: ConnectionStatus) =>
          store.dispatch(new NetworkStatusChange(network.connected))
      );

      await SplashScreen.hide();

      resolve(null);
    });
}

@NgModule({
  declarations: [AppComponent, TimeoutModal],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    StoreModule,
    SharedModule,
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.MSPOS_IS_DEPLOYED_BUILD
    }),
    NgxsResetPluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgSelectModule,
    NgIdleModule.forRoot(),
    PosManagerMenuModule,
    MatMomentDateModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    BlurButtonDirectiveModule,
    InputFocusDirectiveModule,
    ...MODAL_MODULES,
    ...MENU_MODULES,
    MenuModule
  ],
  providers: [
    NfcReaderService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    NgxActioncableService,
    Deploy,
    { provide: ErrorHandler, useFactory: getErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [Store, NfcReaderService]
    }
  ],
  bootstrap: [AppComponent],
  schemas: []
})
export class AppModule {}
