import { CardTerminalTypes } from 'src/app/models/payment.model';

export enum TimeDuration {
  Millisecond = 1,
  Second = TimeDuration.Millisecond * 1000,
  Minute = TimeDuration.Second * 60,
  Hour = TimeDuration.Minute * 60
}

export enum DiscountType {
  Dollar = 'dollar',
  Percent = 'percent'
}

export enum WeightUnit {
  Gram = 'g',
  Ounce = 'oz',
  Pound = 'lb',
  Kilograms = 'kg'
}

export enum MealPlanType {
  POINTS = 'Points',
  DECLINING_BALANCE = 'Declining Balance',
  UNLIMITED = 'Unlimited'
}

export enum MealPlanPeriod {
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  UNLIMITED = 'Unlimited'
}

export interface BaseData {
  id: number;
  name: string;
}

export interface Printer extends BaseData {
  // just keep it empty, we only need the interface name Printer instead of BaseData
}

export interface Category extends BaseData {
  category_id: number;
  categories_meals: CategoryMeal[];
}

export interface CategoryMeal {
  meal_id: number;
  sort: number;
  course: number;
}

export interface Operator {
  id: number;
  first_name: string;
  last_name: string;
  name?: string;
  display_name?: string;
  operator_id: string;
  pin_code: string;
  request_pin_code_reset: boolean;
  permissions: OperatorPermission;
  color?: {
    background: string;
    text: string;
  };
  manager_access_jwt?: string;
}

export interface OperatorPermission {
  discount: boolean;
  refund: boolean;
  void: boolean;
  cancel: boolean;
  assign_tables: boolean;
  schedules_and_services: boolean;
}

export interface CardTerminal extends BaseData {
  ip: string;
  type: CardTerminalTypes;
}

export interface BaseDiscount extends BaseData {
  type: DiscountType;
}

export interface Discount extends BaseDiscount {
  sort_order: number;
}

export interface ScaleContainer extends BaseData {
  weight: string;
  unit: WeightUnit;
}

export interface Diet extends BaseData {
  diet_id: number;
  default: boolean;
}

export interface Texture extends BaseData {
  texture_id: number;
  default: boolean;
}

export interface Fluid extends BaseData {
  fluid_id: number;
}

export interface ChargeAccount extends BaseData {
  available_credit: number;
}

export interface MealPlan extends BaseData {
  balance: number;
  remaining_balance_formatted: string;
  tender_type: MealPlanType;
  point_values?: PointValue;
  period: MealPlanPeriod;
  balance_used?: number;
}

export interface PointValue {
  breakfast: number;
  dinner: number;
  lunch: number;
}

export interface GiftCard extends Omit<BaseData, 'name'> {
  identification_number: string;
  expiry_date: string;
  balance: number;
  secure_code: string;
}

export interface Department extends BaseData {
  code: string;
  charge_accounts: ChargeAccountDepartment[];
  tax_exempt: boolean;
}

export interface ChargeAccountDepartment extends BaseData {
  available_balance: number;
  usage_restrictions: string;
}

export interface Meal {
  name: string;
  /** 'breakfast' | 'lunch' | 'dinner' */
  keyName: string;
  id: number;
  /** 'meal' | 'snack' */
  type: string;
  /** 'am' | 'pm' | 'hs' */
  time: string;
  /** 'AMBrk', 'PMLun', 'HSDin' */
  suffix: string;
  /** 'Brk' | 'Lun' | 'Din' */
  shortName?: string;
}

export interface VisitingFacility {
  facility_id: number;
  facility_name: string;
}

export enum MealTime {
  Breakfast = 1,
  Lunch = 3,
  Dinner = 5
}

export enum WebsocketEvent {
  FLOOR_PLAN_LAYOUT_CHANGED = 'floor_plan_layout_changed',
  TICKET_CREATED = 'ticket_created',
  TICKET_CLOSED = 'ticket_closed',
  TICKET_LOCK_CHANGED = 'ticket_lock_changed',
  FLOOR_PLAN_UPDATED = 'floor_plan_order_notification',
  TABLE_ASSIGNMENT_CHANGE = 'table_assignment_change',
  PENDING_SEAT_ASSIGNMENT_CREATED = 'pending_seat_assignment_created',
  PENDING_SEAT_ASSIGNMENT_SEATED = 'pending_seat_assignment_seated',
  PENDING_SEAT_ASSIGNMENT_DELETED = 'pending_seat_assignment_deleted',
  PRODUCTION_COMPLETED = 'production_completed',
  TAKEOUT_ORDER_STATUS_CHANGE = 'takeout_order_status_change',
  POS_OPERATOR_CREATED = 'pos_operator_created',
  POS_OPERATOR_UPDATED = 'pos_operator_updated',
  POS_OPERATOR_DELETED = 'pos_operator_deleted',
  RESERVATION_CREATED = 'reservation_created',
  RESERVATION_UPDATED = 'reservation_updated',
  RESERVATION_DELETED = 'reservation_deleted',
  FIRE_ORDER = 'fire_order_notification',
  TABLE_STATUS_CHANGED = 'table_status_changed',
  QUANTITY_RESTRICTION_CHANGED = 'quantity_restriction_changed',
  QUANTITY_RESTRICTION_DELETED = 'quantity_restriction_deleted'
}

export type WSMessageData<T> = {
  event: WebsocketEvent;
  data: T;
};

export type StateObject<T> = {
  data: T;
  isLoaded?: boolean;
  isProcessing?: boolean;
};

export type NormalizedObject<T, K extends string | number = string> = {
  byId: K extends number ? _.NumericDictionary<T> : _.Dictionary<T>;
  allIds: K[];
};
