import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import _ from 'lodash';
import { POSManagerOperator } from 'src/app/models/pos-manager.model';
import {
  CreatePOSOperator,
  GetPOSOperators,
  CreatePOSOperatorSuccess,
  UpdatePOSOperator,
  UpdatePOSOperatorSuccess,
  DeletePOSOperator,
  DeletePOSOperatorSuccess
} from './pos-operator.action';
import { PosOperatorService } from 'src/app/services/pos-manager/pos-operator/pos-operator.service';
import { catchError, tap } from 'rxjs';
import { ExceptionError } from '../../error/error.action';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ToastController } from '@ionic/angular';

export interface PosManagerOperatorStateModel {
  operators: POSManagerOperator[];
  isLoading: boolean;
}

@State<PosManagerOperatorStateModel>({
  name: 'operators',
  defaults: {
    isLoading: false,
    operators: []
  }
})
@Injectable({ providedIn: 'root' })
export class PosManagerOperatorState {
  constructor(
    private operatorService: PosOperatorService,
    private modalService: ModalService,
    private toastController: ToastController
  ) {}

  @Action(GetPOSOperators)
  getOperators(ctx: StateContext<PosManagerOperatorStateModel>) {
    ctx.patchState({ isLoading: true });
    return this.operatorService.getOperators().pipe(
      tap((operators) => {
        //TODO: sort items before storing them
        ctx.patchState({ operators, isLoading: false });
      }),
      catchError((e) =>
        ctx.dispatch(
          new ExceptionError('There was an error while fetching the operators!')
        )
      )
    );
  }

  @Action(CreatePOSOperator)
  createOperators(
    ctx: StateContext<PosManagerOperatorStateModel>,
    { newOperator }: CreatePOSOperator
  ) {
    this.modalService.showLoadingModal('Saving...');
    return this.operatorService.createOperator(newOperator).pipe(
      tap(async (operator) => {
        const toast = await this.toastController.create({
          color: 'primary',
          duration: 2000,
          message: 'Operator saved successfully.',
          position: 'top'
        });
        await toast.present();
        ctx.dispatch([
          new CreatePOSOperatorSuccess(operator),
          new GetPOSOperators()
        ]);
      }),
      catchError(async (err) => {
        await this.modalService.closeLoadingModal();
        let message = 'There was a problem while creating operator.';
        if (err?.error?.error?.length) {
          message = err?.error?.error[0];
        }
        return ctx.dispatch(new ExceptionError(message));
      })
    );
  }

  @Action(UpdatePOSOperator)
  updateOperators(
    ctx: StateContext<PosManagerOperatorStateModel>,
    { operator }: UpdatePOSOperator
  ) {
    this.modalService.showLoadingModal('Saving...');
    return this.operatorService.updateOperator(operator).pipe(
      tap(async (data) => {
        const toast = await this.toastController.create({
          color: 'primary',
          duration: 2000,
          message: 'Operator saved successfully.',
          position: 'top'
        });
        await toast.present();
        ctx.dispatch([new UpdatePOSOperatorSuccess(), new GetPOSOperators()]);
      }),
      catchError(async (err) => {
        await this.modalService.closeLoadingModal();
        let message = 'There was a problem while updating operator.';
        if (err?.error?.error?.length) {
          message = err?.error?.error[0];
        }
        return ctx.dispatch(new ExceptionError(message));
      })
    );
  }

  @Action(DeletePOSOperator)
  deleteOperators(
    ctx: StateContext<PosManagerOperatorStateModel>,
    { id }: DeletePOSOperator
  ) {
    this.modalService.showLoadingModal('Deleting...');
    return this.operatorService.deleteOperator(id).pipe(
      tap(async () => {
        const toast = await this.toastController.create({
          color: 'primary',
          duration: 2000,
          message: 'Operator deleted successfully.',
          position: 'top'
        });
        await toast.present();
        ctx.dispatch([new DeletePOSOperatorSuccess(), new GetPOSOperators()]);
      }),
      catchError(async (err) => {
        await this.modalService.closeLoadingModal();
        let message = 'There was a problem while deleting operator.';
        if (err?.error?.error?.length) {
          message = err?.error?.error[0];
        }
        return ctx.dispatch(new ExceptionError(message));
      })
    );
  }
}
