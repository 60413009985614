import { format } from 'date-fns';
import moment from 'moment';

import { CommonConstant } from 'src/app/constants';
import { MealDeliveryAreaTiming, Ticket, TicketSource } from 'src/app/models';

export class TicketHelper {
  static calculateFormattedTakeoutDateTime(
    ticket: Partial<Ticket>,
    locationTimings: MealDeliveryAreaTiming[]
  ): string {
    let formatted_takeout_date_time = '';
    const isNew = !!ticket.created_at;
    if (isNew) {
      // new ticket has ticket date as object
      //If a MDA timing is part of the ticket, we'll need to format the timing.
      if (ticket.meal_delivery_area_timing_id) {
        const timing = locationTimings.find(
          (x) => x.id === +ticket.meal_delivery_area_timing_id
        );

        // If a timing is present, it will need a custom calculation. Otherwise, it will mean it's ASAP.
        if (timing) {
          // First we create a new Date object (Today).
          const d = new Date();
          // This function calculates the next day date based on the date provided - e.g. The next Monday based on Today.
          // If the next Monday was today, it will return the current date. This is okay for Takeout and Delivery as we can only place orders up to 2 days in advance.
          // Note: In Touch, Monday is 0 and Sunday is 6. In Javascript, Sunday is 0 and Saturday is 6.
          // That's the reason (timing.day === 6 ? 0 : timing.day + 1) is required within the function.
          d.setDate(
            d.getDate() +
              (((timing.day === 6 ? 0 : timing.day + 1) + 7 - d.getDay()) % 7)
          );
          // Finally, the date returned by our calculation is formatted according to what needs to appear in the UI.
          // The time won't be based on the Date Object but on the timing from times.
          // The timings hours and minutes can be single digits so we run a function to add 0 when applicable.
          formatted_takeout_date_time = `${format(
            d,
            'EEE, MMM dd, yyyy'
          )} | ${timing.from_hr.toString().padStart(2, '0')}:${timing.from_min
            .toString()
            .padStart(2, '0')} ${timing.from_am ? 'AM' : 'PM'}`;
        }
      } else {
        formatted_takeout_date_time =
          moment(ticket.ticket_date).format(
            CommonConstant.MOMENT_DATE_TIME.TAKEOUT_DISPLAY_DATE
          ) + ' | ASAP';
      }
    } else {
      // in this case we have ticket_date or scheduled_ticket_date and scheduled_ticket_time
      const dateStr = `${ticket.ticket_date || ticket.scheduled_ticket_date} ${
        ticket.scheduled_ticket_time || ''
      }`;
      let formatString = CommonConstant.MOMENT_DATE_TIME.DATE_TIME;

      if (ticket.scheduled_ticket_date) {
        formatString = CommonConstant.MOMENT_DATE_TIME.DATE_TIME;
      }

      //If a MDA timing is part of the ticket, we'll need to format the timing.
      if (ticket.meal_delivery_area_timing_id) {
        formatted_takeout_date_time = moment(dateStr, formatString).format(
          CommonConstant.MOMENT_DATE_TIME.TAKEOUT_DISPLAY_DATE_TIME
        );
      } else {
        formatted_takeout_date_time = moment(dateStr, formatString).format(
          CommonConstant.MOMENT_DATE_TIME.TAKEOUT_DISPLAY_DATE
        );
        formatted_takeout_date_time += ' | ASAP';
      }
    }
    //Update formatted takeout date time on ticket.
    return formatted_takeout_date_time;
  }

  static initializeTicket(ticket: Ticket): Ticket {
    ticket.calculated_total = 0;
    ticket.mealplan_balance_used = 0;
    ticket.outstanding_balance = 0;
    ticket.pre_tax_subtotal = 0;
    ticket.total_base_price = 0;
    ticket.total_discounts = 0;
    ticket.tax_total = 0;
    ticket.ticket_items = [];
    ticket.can_be_cancelled = true;
    ticket.order_source = TicketSource.POS;
    return ticket;
  }
}
