import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EMPTY, map, Observable, of, switchMap, tap } from 'rxjs';
import _ from 'lodash';

import {
  BaseDiner,
  Diner,
  MealTime,
  MenuTallyFoodDetail
} from 'src/app/models';
import { BaseService } from 'src/app/services/base.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { ApiType } from 'src/app/pos.config';
import { ModalService } from 'src/app/services/modal/modal.service';

interface MenuTallyResult {
  meal_id: MealTime;
  person_id: number;
  food_details: MenuTallyFoodDetail[];
}

interface MenuTallyResponse {
  success: boolean;
  result: MenuTallyResult[];
}

@Injectable({
  providedIn: 'root'
})
export class DinerService extends BaseService {
  constructor(
    store: Store,
    private http: HttpClient,
    private readonly errorService: ErrorService,
    private readonly _modalService: ModalService
  ) {
    super(store);
  }

  get dinersUrl() {
    return this.getLocationUrl('diners');
  }

  getDiners(
    name: string,
    patients_only: boolean,
    visiting_facility_id: number
  ): Observable<BaseDiner[]> {
    let data: any = {
      name,
      patients_only
    };

    if (visiting_facility_id) {
      data = { ...data, visiting_facility_id };
    }

    return this.http
      .get<{ diners: BaseDiner[] }>(this.dinersUrl, { params: data })
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.diners)
      );
  }

  getDinerByBarcode(
    barcode: string,
    loadingText?: string
  ): Observable<BaseDiner[]> {
    const data = { barcode, loadingText };
    return this.http
      .get<{ diners: BaseDiner[] }>(this.dinersUrl, { params: data })
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.diners)
      );
  }

  getDinerDetail(diner_id: number) {
    const addDinerLocalAttributes = (diner: Diner) => {
      diner.local_attributes = {};
      const diets = _.orderBy((diner && diner.diets) || [], ['order']) || [];
      const diet = (diets.length && diets[0]?.name) || ' ';
      const texture = (diner.textures.length && diner.textures[0]?.name) || ' ';
      const fluid = (diner.fluid && diner.fluid.name) || ' ';

      diner.local_attributes.dietOrderName = `${diet}/${texture}/${fluid}`;
      diner.local_attributes.dietInterventions =
        (diner.diet_interventions &&
          diner.diet_interventions.map((i) => i.name).join(', ')) ||
        '';
      diner.local_attributes.dietTypes =
        diets.map((i) => i.name).join(' | ') || 'N/A';
      return diner;
    };

    return this.http
      .get<{ diner: Diner }>(`${this.dinersUrl}/${diner_id}`)
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => addDinerLocalAttributes(response.diner))
      );
  }

  getMenuTally(diner_id: number, meal_id: number): Observable<MenuTallyResult> {
    const getMenu$ = this.http
      .get<MenuTallyResponse>(
        `${this.dinersUrl}/${diner_id}/select_menu_tally`,
        {
          params: { meal_id }
        }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.MenuTallyLoading),
        tap(() => this._modalService.closeLoadingModal()),
        switchMap(({ result, success }) =>
          !success || !result.length ? EMPTY : of(result[0])
        )
      );

    this._modalService.showMenuTallyLoadingModal();

    return getMenu$;
  }
}
