import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { StateReset } from 'ngxs-reset-plugin';
import { denormalize } from 'normalizr';
import { produce } from 'immer';
import { filter, finalize, map, Observable, switchMap, tap } from 'rxjs';
import _ from 'lodash';

import {
  FloorplanStatus,
  LocationType,
  PendingAssignment,
  PendingAssignmentStatusChangeType,
  Table,
  TableReservation
} from 'src/app/models';
import { NormalizedEntities } from 'src/app/models/shared/normalized-entities.model';
import { DiningService, ModalService } from 'src/app/services';
import {
  AddTemporarySeat,
  AddTemporarySeatSuccess,
  GetTable,
  GetTableManagementFloorPlan,
  GetTables,
  GetTablesSuccess,
  GetTableSuccess,
  SelectTable,
  UpdateSelectedTableEditorData,
  UpdateDataAfterAssignment,
  SaveFloorPlanTables,
  EditSelectedTableSeat,
  UpdateTableAssignment,
  UpdateFloorPlanEditingStatus,
  FireTable,
  ToggleTablesListVisibility,
  UpdateTableStatus,
  RemoveTemporarySeat,
  UpdateLocalTables,
  UpdatePendingSeatAssignment,
  SelectNewTable,
  TableStatusChangedWs,
  FloorplanOrderUpdatedWS,
  PendingSeatAssignmentSeatedWsEvent,
  TicketCreatedWsEvent,
  ReservationCreatedWsEvent,
  UnSelectTable
} from './tables.action';
import { TableProvider } from 'src/app/providers';
import {
  tablesArraySchema,
  tableSchema
} from 'src/app/models/normalized-models/normalized-table.model';
import { GetAssignedResidentsSuccess } from '../diners/diners.action';
import { SeatMapProvider } from 'src/app/providers/seat-map.provider';
import { TableConstant } from 'src/app/constants';
import { EditTableModal } from 'src/app/modals/dining/edit-table/edit-table.modal';
import {
  CancelTicket,
  TemporarySeatMealChangeSuccess,
  TicketClosedWsEvent,
  UpdateFireTableSuccess
} from '../tickets/tickets.action';
import { NormalizrHelper, TableHelper } from 'src/app/helpers';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';
import { SEATS_STATE_TOKEN } from 'src/app/store/seats/seats.state';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { TableReservationsState } from './reservations/table-reservation.state';
import { TableStateModel, TABLES_STATE_TOKEN } from './tables.state.model';
import {
  CreateTableReservationSuccess,
  DeleteTableReservationSuccess,
  EditTableReservationSuccess
} from 'src/app/store/tables/reservations/table-reservation.action';
import { TicketsState } from 'src/app/store/tickets/tickets.state';
import { TransactionsState } from 'src/app/store/transactions/transactions.state';
import { DinersState } from 'src/app/store/diners/diners.state';
import { TicketItemsState } from 'src/app/store/ticket-items/ticket-items.state';
import {
  PendingAssignmentDeletedWsEvent,
  PendingSeatCreatedWsEventSuccess
} from 'src/app/store/host-mode/host-mode.action';

@State({
  name: TABLES_STATE_TOKEN,
  defaults: {
    newTables: {
      data: [],
      isLoaded: false
    },
    posListingTables: [], // this one is only for table listing on floor plan and table page
    tables: {
      byId: {},
      allIds: []
    },
    selectedTable: { data: null, isLoaded: false, isProcessing: false },
    resetSelectedTableIds: false,
    selectedTableManagementFloorPlan: [],
    selectedTableManagementLocationId: null,
    selectedTableManagementEditingStatus: false,
    showTablesList: false
  },
  children: [TableReservationsState]
})
@Injectable()
export class TablesState {
  constructor(
    private readonly diningService: DiningService,
    private readonly modalService: ModalService,
    private readonly store: Store,
    private readonly seatMapProvider: SeatMapProvider,
    private readonly tableProvider: TableProvider,
    private readonly navController: NavController
  ) {}

  /** Actions area */
  @Action(GetTables)
  getTables(ctx: StateContext<TableStateModel>): Observable<Table[]> {
    const locationId =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.id;
    const operator = this.store.selectSnapshot(APP_STATE_TOKEN).operator;
    return this.diningService.getFloorPlan(locationId).pipe(
      tap((tables) =>
        ctx.patchState({
          newTables: {
            data: tables,
            isLoaded: true
          },

          posListingTables: TableHelper.updateTableListing(tables, operator)
        })
      ),
      map((tables) =>
        tables.map((table) => {
          table = this.tableProvider.addTableLocalAttributes(table);
          table.seats = _.sortBy(table.seats, 'number');
          table.reservations = [];
          return table;
        })
      ),
      tap((tables) => ctx.dispatch(new GetTablesSuccess(tables)))
    );
  }

  @Action(GetTable, { cancelUncompleted: true })
  getTable(ctx: StateContext<TableStateModel>, { tableId }: GetTable) {
    return this.diningService
      .getTableDetail(tableId)
      .pipe(
        switchMap((table) =>
          ctx.dispatch(
            new GetTableSuccess(<Table>table, [_.cloneDeep(<Table>table)])
          )
        )
      );
  }

  @Action(GetTable, { cancelUncompleted: true })
  getAssignedResidents(
    ctx: StateContext<TableStateModel>,
    { tableId }: GetTable
  ) {
    return this.diningService
      .getAssignedResidents(tableId)
      .pipe(
        switchMap((residents) =>
          ctx.dispatch(new GetAssignedResidentsSuccess(residents))
        )
      );
  }

  @Action(GetTablesSuccess)
  getTablesSuccess(
    ctx: StateContext<TableStateModel>,
    { tables }: GetTablesSuccess
  ): void {
    const normalizedTables =
      NormalizrHelper.normalizeTables(tables).entities.tables;
    ctx.setState(
      produce((draft: TableStateModel) => {
        if (normalizedTables) {
          draft.tables = {
            byId: { ...draft.tables.byId, ...normalizedTables },
            allIds: _.union(
              draft.tables.allIds,
              Object.keys(normalizedTables).map((key) => +key)
            )
          };
        }
      })
    );
  }

  @Action(UpdateTableStatus)
  updateTableStatus(
    ctx: StateContext<TableStateModel>,
    { tableId }: UpdateTableStatus
  ) {
    const normalizedSeats =
      this.store.selectSnapshot(SEATS_STATE_TOKEN).seats.byId;
    const entities: NormalizedEntities = {
      tables: ctx.getState().tables.byId,
      seats: normalizedSeats
    };
    let denormalizeTable: Table = denormalize(tableId, tableSchema, entities);
    if (denormalizeTable && denormalizeTable.seats) {
      denormalizeTable =
        this.tableProvider.addTableLocalAttributes(denormalizeTable);
      const normalizeTables = NormalizrHelper.normalizeTables([
        denormalizeTable
      ]).entities.tables;
      ctx.setState(
        produce((draft: TableStateModel) => {
          if (
            draft.tables.byId &&
            normalizeTables &&
            normalizeTables[tableId]
          ) {
            draft.tables.byId[tableId] = normalizeTables[tableId];
          }
        })
      );
    }
  }

  @Action(UpdateLocalTables)
  updateLocalTables(
    ctx: StateContext<TableStateModel>,
    { tables }: UpdateLocalTables
  ) {
    const normalizedTables = NormalizrHelper.normalizeTables(<Table[]>tables)
      .entities.tables;
    ctx.setState(
      produce((draft: TableStateModel) => {
        if (normalizedTables) {
          tables.forEach(
            (table) =>
              (draft.tables.byId[table.id] = _.merge(
                draft.tables.byId[table.id],
                normalizedTables[table.id]
              ))
          );
        }
      })
    );
  }

  @Action(FireTable)
  fireTable(
    ctx: StateContext<TableStateModel>,
    { tableId, course }: FireTable
  ) {
    const operatorId = this.store.selectSnapshot(APP_STATE_TOKEN).operator.id;
    return this.diningService
      .fireTable(tableId, operatorId, null || course)
      .pipe(
        filter((success) => !!success),
        tap(() =>
          // update fired status for all the tickets item in this table or all seats by specific course
          ctx.dispatch(new UpdateFireTableSuccess(tableId, course))
        )
      );
  }

  @Action(UpdateTableAssignment)
  updateTableAssignment(
    ctx: StateContext<TableStateModel>,
    { table_ids, operator_id }: UpdateTableAssignment
  ) {
    ctx.patchState({ resetSelectedTableIds: false });
    return this.diningService
      .updateTableAssignment(table_ids, operator_id)
      .pipe(
        tap((success) => {
          if (success) {
            ctx.dispatch(new UpdateDataAfterAssignment(table_ids, operator_id));
          }
        })
      );
  }

  @Action(UpdateDataAfterAssignment)
  updateDataAfterAssignment(
    ctx: StateContext<TableStateModel>,
    { table_ids, operator_id }: UpdateTableAssignment
  ) {
    const staff = this.store
      .selectSnapshot(APP_STATE_TOKEN)
      .facility_details?.operators?.find((x) => x.id === operator_id);

    const data = {
      assigned_staff_id: staff ? staff.id : null,
      assigned_staff_name: staff ? staff.name : null
    };

    ctx.setState(
      produce((draft) => {
        table_ids.forEach((tableId) => {
          draft.tables.byId[tableId] = _.extend(
            draft.tables.byId[tableId],
            data
          );
          const draftNewTable = draft.newTables.data.find(
            (table) => table.id === tableId
          );
          draftNewTable.assigned_staff_id = data.assigned_staff_id;
          draftNewTable.assigned_staff_name = data.assigned_staff_name;
        });
        draft.resetSelectedTableIds = true;

        draft.posListingTables = TableHelper.updateTableListing(
          draft.newTables.data,
          staff
        );
      })
    );
  }

  @Action(SelectTable)
  selectTable(ctx: StateContext<TableStateModel>, { tableId }: SelectTable) {
    ctx.patchState({
      selectedTable: { data: tableId, isLoaded: false }
    });
  }

  @Action(AddTemporarySeat)
  addTemporarySeat(ctx: StateContext<TableStateModel>) {
    const state = ctx.getState();
    return this.diningService
      .createTemporarySeat(state.selectedTable.data)
      .pipe(tap((seat) => ctx.dispatch(new AddTemporarySeatSuccess(seat))));
  }

  @Action(AddTemporarySeatSuccess)
  addTemporarySeatSuccess(
    ctx: StateContext<TableStateModel>,
    { seat }: AddTemporarySeatSuccess
  ) {
    ctx.setState(
      produce((draft: TableStateModel) => {
        if (draft.tables.byId && draft.tables.byId[draft.selectedTable.data]) {
          draft.tables.byId[draft.selectedTable.data].seats.push(seat.id);
        }
      })
    );
  }

  @Action(RemoveTemporarySeat)
  removeTemporarySeat(
    ctx: StateContext<TableStateModel>,
    { tableId, seatId }: RemoveTemporarySeat
  ) {
    ctx.setState(
      produce((draft: TableStateModel) => {
        if (draft.tables.byId && draft.tables.byId[tableId]) {
          // remove undefined seat.
          _.remove(
            draft.tables.byId[tableId].seats,
            (item) => item === seatId || !item
          );
        }
      })
    );
  }

  @Action(GetTableManagementFloorPlan)
  getTableManagementFloorPlan(
    ctx: StateContext<TableStateModel>,
    { locationId }: GetTableManagementFloorPlan
  ) {
    const defaultLocationId =
      this.store.selectSnapshot(APP_STATE_TOKEN).device?.pos_location_id;

    if (defaultLocationId === locationId) {
      const normalizedSeats =
        this.store.selectSnapshot(SEATS_STATE_TOKEN).seats.byId;
      const entities: NormalizedEntities = {
        tables: ctx.getState().tables.byId,
        seats: _.pickBy(normalizedSeats, (s) => !s.is_temporary_seat)
      };
      const tables: Table[] = denormalize(
        ctx.getState().tables.allIds,
        tablesArraySchema,
        entities
      );
      ctx.patchState({
        selectedTableManagementFloorPlan: _.orderBy(
          tables.map((table) => this.parseSeatMapData(table)) || [],
          'sort'
        ),
        selectedTableManagementLocationId: locationId
      });
      return;
    }
    return this.diningService.getFloorPlan(locationId).pipe(
      map((tables) => tables.map((table) => this.parseSeatMapData(table))),
      tap((tables: Table[]) => {
        ctx.patchState({
          selectedTableManagementFloorPlan: _.orderBy(tables || [], 'sort'),
          selectedTableManagementLocationId: locationId
        });
      })
    );
  }

  private parseSeatMapData(table: Table): Table {
    return {
      ...table,
      ...this.seatMapProvider.parseSeatAPIData(
        table?.seats || [],
        table.tableshape === TableConstant.TABLE_SHAPE.CIRCLE
      )
    };
  }

  @Action(EditSelectedTableSeat)
  editSelectedTableSeat(
    ctx: StateContext<TableStateModel>,
    { table, diagram }: EditSelectedTableSeat
  ) {
    let selectedTable = table;
    // update table location if necessary
    if (diagram && diagram.model && diagram.model.nodeDataArray) {
      const tables = diagram.model.nodeDataArray;
      const updateTables = _.cloneDeep(
        ctx.getState().selectedTableManagementFloorPlan
      );
      let hasPositionChanged = false;
      updateTables.forEach((updateTable, index) => {
        if (
          tables &&
          tables[index] &&
          tables[index].newPosition &&
          tables[index].newPosition !== updateTable.location
        ) {
          updateTables[index].location = tables[index].newPosition;
          hasPositionChanged = true;
        }
      });
      if (hasPositionChanged) {
        ctx.patchState({
          selectedTableManagementFloorPlan: _.orderBy(
            updateTables || [],
            'sort'
          ),
          selectedTableManagementEditingStatus: true
        });
      }
      selectedTable = updateTables.find((x) => x.id === table.id);
    }
    this.modalService.showModal(EditTableModal, {
      table: _.cloneDeep(selectedTable)
    });
  }

  @Action(UpdateSelectedTableEditorData)
  updateSelectedTableEditorData(
    ctx: StateContext<TableStateModel>,
    { table }: UpdateSelectedTableEditorData
  ) {
    const floorPlanTables = _.cloneDeep(
      ctx.getState().selectedTableManagementFloorPlan
    );
    const selectedTableIndex = floorPlanTables.findIndex(
      (t) => t.id === table.id
    );
    if (selectedTableIndex >= 0) {
      floorPlanTables[selectedTableIndex] = table;
      ctx.patchState({
        selectedTableManagementFloorPlan: _.orderBy(
          floorPlanTables || [],
          'sort'
        ),
        selectedTableManagementEditingStatus: true
      });
    }
  }

  @Action(SaveFloorPlanTables)
  saveFloorPlanTables(
    ctx: StateContext<TableStateModel>,
    { tables }: SaveFloorPlanTables
  ) {
    // enforce values always update to hide the save button
    ctx.patchState({ selectedTableManagementEditingStatus: true });

    const prevTables = ctx.getState().selectedTableManagementFloorPlan;
    const updateTables = tables.map((table) =>
      this.seatMapProvider.parseTableUpdate(prevTables, table)
    );
    const currentFloorPlanLocationId =
      ctx.getState().selectedTableManagementLocationId;
    ctx.patchState({ selectedTableManagementEditingStatus: false });

    return this.diningService.updateFloorPlan(
      currentFloorPlanLocationId,
      updateTables
    );
    // .pipe(
    //   tap(() => {
    //     // do nothing as pos v1 need refresh after table management update (there is no WS for table management updates)
    //   })
    // );
  }

  @Action(UpdateFloorPlanEditingStatus)
  updateFloorPlanEditingStatus(
    ctx: StateContext<TableStateModel>,
    { isEditing }: UpdateFloorPlanEditingStatus
  ) {
    ctx.patchState({ selectedTableManagementEditingStatus: isEditing });
  }

  @Action(ToggleTablesListVisibility)
  toggleTablesListVisibility(ctx: StateContext<TableStateModel>) {
    ctx.setState(
      produce((draft: TableStateModel) => {
        draft.showTablesList = !draft.showTablesList;
      })
    );
  }

  @Action(UpdatePendingSeatAssignment)
  updatePendingSeatAssignment(
    ctx: StateContext<TableStateModel>,
    { type, data }: UpdatePendingSeatAssignment
  ) {
    const { tableId } = data;
    switch (type) {
      case PendingAssignmentStatusChangeType.CREATED:
        const { createdItems } = data;
        ctx.setState(
          produce((draft: TableStateModel) => {
            draft.tables.byId[tableId].pending_seat_assignments = _.concat(
              draft.tables.byId[tableId].pending_seat_assignments,
              createdItems
            );
          })
        );
        break;
      case PendingAssignmentStatusChangeType.SEATED:
      case PendingAssignmentStatusChangeType.DELETED:
        const { deletedId } = data;
        ctx.setState(
          produce((draft: TableStateModel) => {
            _.remove(
              draft.tables.byId[tableId].pending_seat_assignments,
              (o) => o.id === deletedId
            );
          })
        );
        break;
    }
  }

  @Action(SelectNewTable)
  selectNewTable(ctx: StateContext<TableStateModel>, payload: SelectNewTable) {
    ctx.dispatch(
      new StateReset(
        DinersState,
        TicketItemsState,
        TransactionsState,
        TicketsState
      )
    );

    // const previousTableId = ctx.getState().selectedTable.data;

    ctx.setState(
      patch({
        selectedTable: patch({
          data: payload.tableId,
          isLoaded: false
        })
      })
    );

    // if (!previousTableId) {
    //   this.navController.navigateRoot(PageConstant.TABLE_PAGE);
    // }

    return ctx.dispatch(new GetTable(payload.tableId)).pipe(
      finalize(() =>
        ctx.setState(
          patch({
            selectedTable: patch({ isLoaded: true })
          })
        )
      )
    );
  }

  @Action(TemporarySeatMealChangeSuccess)
  temporarySeatMealChangeSuccess(
    ctx: StateContext<TableStateModel>,
    payload: TemporarySeatMealChangeSuccess
  ) {
    const selectedSeatId =
      this.store.selectSnapshot(SEATS_STATE_TOKEN).selectedSeat;
    ctx.setState(
      produce((draft: TableStateModel) => {
        draft.tables.byId[draft.selectedTable.data].seats.splice(
          draft.tables.byId[draft.selectedTable.data].seats.indexOf(
            selectedSeatId
          ),
          1,
          payload.seat.id
        );
      })
    );
  }

  // TODO[Thanh] Should refactor this method to update table status increase reservation changes.
  @Action([
    EditTableReservationSuccess,
    DeleteTableReservationSuccess,
    CreateTableReservationSuccess
  ])
  updateTableReservationSuccess(ctx: StateContext<TableStateModel>) {
    ctx.dispatch(new GetTables());
  }

  // @Action(CreateTableReservationSuccess)
  // createTableReservationSuccess(
  //   ctx: StateContext<TableStateModel>,
  //   payload: CreateTableReservationSuccess
  // ) {
  //   payload.reservation.reservation_tables.forEach((rt) =>
  //     ctx.setState(
  //       produce((draft: TableStateModel) => {
  //         draft.tables.byId[rt.table_id].reservations.push(payload.reservation);
  //       })
  //     )
  //   );
  // }

  // @Action(EditTableReservationSuccess)
  // editTableReservationSuccess(
  //   ctx: StateContext<TableStateModel>,
  //   payload: EditTableReservationSuccess
  // ) {
  //   payload.reservation.reservation_tables.forEach((rt) =>
  //     ctx.setState(
  //       produce((draft: TableStateModel) => {
  //         const reservations = draft.tables.byId[rt.table_id].reservations;
  //         const replaceIdx = reservations.findIndex(
  //           (i) => i.id === payload.reservation.id
  //         );
  //         if (replaceIdx === -1) {
  //           return;
  //         }

  //         reservations.splice(
  //           reservations.findIndex((i) => i.id === payload.reservation.id),
  //           1,
  //           payload.reservation
  //         );
  //       })
  //     )
  //   );
  // }

  // @Action(DeleteTableReservationSuccess)
  // deleteTableReservationSuccess(
  //   ctx: StateContext<TableStateModel>,
  //   payload: DeleteTableReservationSuccess
  // ) {
  //   ctx.setState(
  //     produce((draft: TableStateModel) => {
  //       _.forEach(draft.tables.byId, (table) => {
  //         const removeItemIdx = table.reservations.findIndex(
  //           (i) => i.id === payload.id
  //         );
  //         if (removeItemIdx > -1) {
  //           table.reservations.splice(removeItemIdx, 1);
  //         }
  //       });
  //     })
  //   );
  // }

  @Action(CancelTicket)
  deleteTempSeatAfterCancellingTicket(
    ctx: StateContext<TableStateModel>
    // _payload: CancelTicket
  ) {
    const location = this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
    const isDiningRoom = location.type === LocationType.DiningRoom;

    if (!isDiningRoom) {
      return;
    }

    const seatState = this.store.selectSnapshot(SEATS_STATE_TOKEN);
    const seat = seatState.seats.byId[seatState.selectedSeat];

    if (seat?.is_temporary_seat) {
      ctx.setState(
        produce((draft: TableStateModel) => {
          _.remove(
            draft.tables.byId[draft.selectedTable.data].seats,
            (s) => s === seat.id
          );
        })
      );
    }
  }

  @Action(TableStatusChangedWs)
  tableStatusChangedWs(
    ctx: StateContext<TableStateModel>,
    payload: TableStatusChangedWs
  ) {
    ctx.setState(
      produce((draft) => {
        const draftTable = draft.newTables.data.find(
          (i) => i.id === payload.tableId
        );

        if (draftTable && payload.status !== draftTable.status) {
          // dont trigger if status is the same as the floor_plan_order_notification may trigger first and already update the ticket status
          draftTable.status = payload.status;

          draft.posListingTables = TableHelper.updateTableListing(
            draft.newTables.data,
            this.store.selectSnapshot(APP_STATE_TOKEN).operator
          );
        }
      })
    );
  }

  @Action(FloorplanOrderUpdatedWS)
  floorplanOrderUpdatedWS(
    ctx: StateContext<TableStateModel>,
    { data }: FloorplanOrderUpdatedWS
  ) {
    ctx.setState(
      produce((draft) => {
        const draftTable = draft.newTables.data.find(
          (i) => i.id === data.table_id
        );
        if (draftTable) {
          const draftSeat = draftTable.seats.find((s) => s.id === data.seat_id);
          if (draftSeat) {
            draftSeat.floorplan_status = data.floorplan_status;
            draftSeat.device_ticket_uuid = data.device_ticket_uuid;
            draftSeat.ticket_status = data.new_status;
          }
        }
      })
    );
  }

  @Action([PendingAssignmentDeletedWsEvent, PendingSeatAssignmentSeatedWsEvent])
  deletePendingAssignmentFromTable(
    ctx: StateContext<TableStateModel>,
    payload: PendingAssignmentDeletedWsEvent
  ) {
    ctx.setState(
      produce((draft) => {
        const draftTable = draft.newTables.data.find(
          (i) => i.id === payload.table_id
        );

        if (draftTable) {
          _.remove(
            draftTable.pending_seat_assignments,
            (i) => i.id === payload.id
          );
        } else {
          _.forEach(draft.newTables.data, (table) => {
            _.remove(
              table.pending_seat_assignments,
              (i) => i.id === payload.id
            );
          });
        }
      })
    );
  }

  @Action(PendingSeatCreatedWsEventSuccess)
  pendingSeatCreatedWsEventSuccess(
    ctx: StateContext<TableStateModel>,
    payload: PendingSeatCreatedWsEventSuccess
  ) {
    ctx.setState(
      produce((draft) => {
        const draftTable = draft.newTables.data.find(
          (table) => table.id === payload.table.id
        );
        if (draftTable) {
          draftTable.pending_seat_assignments = payload.table
            .pending_seat_assignments as PendingAssignment[];
        }
      })
    );
  }

  @Action(TicketClosedWsEvent)
  ticketClosedWsEvent(
    ctx: StateContext<TableStateModel>,
    { wsData }: TicketClosedWsEvent
  ) {
    const { table_id, seat_id } = wsData;

    if (!table_id) {
      return;
    }

    ctx.setState(
      produce((draft) => {
        const draftTable = draft.newTables.data.find(
          ({ id }) => id === table_id
        );

        if (draftTable) {
          const draftSeat = draftTable.seats.find((i) => i.id === seat_id);
          if (draftSeat) {
            draftSeat.ticket_created_at = null;
            draftSeat.device_ticket_uuid = null;
            draftSeat.floorplan_status = null;
          }
        }

        draft.posListingTables = TableHelper.updateTableListing(
          draft.newTables.data,
          this.store.selectSnapshot(APP_STATE_TOKEN).operator
        );
      })
    );
  }

  @Action(TicketCreatedWsEvent)
  ticketCreatedWsEvent(
    ctx: StateContext<TableStateModel>,
    payload: TicketCreatedWsEvent
  ) {
    const tableId = payload.data.table_id;

    if (!tableId) {
      return;
    }

    ctx.setState(
      produce((draft) => {
        const draftTable = draft.newTables.data.find(
          ({ id }) => id === tableId
        );

        if (draftTable) {
          const seatId = payload.data.seat_id;
          const draftSeat = draftTable.seats.find((i) => i.id === seatId);
          if (draftSeat) {
            draftSeat.ticket_created_at = payload.data.ticket_created_at;
            draftSeat.device_ticket_uuid = payload.data.device_ticket_uuid;
            draftSeat.floorplan_status = FloorplanStatus.EmptyTicket;
          }
        }

        draft.posListingTables = TableHelper.updateTableListing(
          draft.newTables.data,
          this.store.selectSnapshot(APP_STATE_TOKEN).operator
        );
      })
    );
  }

  @Action(ReservationCreatedWsEvent)
  reservationCreatedWsEvent(
    ctx: StateContext<TableStateModel>,
    payload: ReservationCreatedWsEvent
  ) {
    ctx.setState(
      produce((draft) => {
        const draftTables = draft.newTables.data;

        payload.wsData.reservation_tables.forEach((table) => {
          const draftTable = draftTables.find((i) => i.id === table.table_id);
          const isReservationExist = !!draftTable.reservations.find(
            (reservation) => reservation.id === payload.wsData.id
          );
          if (!isReservationExist) {
            const tableReservation: TableReservation = {
              ...payload.wsData,
              reservation_tables: []
            };

            draftTable.reservations.push(tableReservation);
          }
        });
      })
    );
  }

  @Action(UnSelectTable)
  unSelectTable(ctx: StateContext<TableStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.selectedTable.data = null;
      })
    );
  }
}
