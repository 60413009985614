import { produce } from 'immer';
import _ from 'lodash';

import {
  FloorPlanTableReservation,
  HostModeStatus,
  NormalizedEntities,
  NormalizedObject,
  NormalizedSeat,
  NormalizedTable,
  NormalizedTicket,
  NormalizedTicketItem,
  Operator,
  PendingAssignment,
  PosTableStatus,
  ReservationMealConfiguration,
  ReservationSettings,
  ReservationTable,
  Seat,
  Table,
  TableListingStatus,
  TableReservation,
  TicketItemStatus,
  TicketStatus,
  TimeDuration,
  tablesArraySchema
} from 'src/app/models';
import { PosTime, TimeRange } from 'src/app/models/time.model';
import { DateHelper } from './date.helper';
import { TimeHelper } from './time.helper';
import { CommonConstant, TableConstant } from 'src/app/constants';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import {
  PosFloorplanSeat,
  PosFloorplanTable,
  PosListingTable
} from 'src/app/store/tables/tables.state.model';
import { differenceInMinutes, minutesToHours } from 'date-fns';
import { denormalize } from 'normalizr';

const MAX_HOUR = 5;

export class TableHelper {
  static courseStatuses(
    seats: Seat[],
    isCoursingEnabled: boolean,
    getTicketBySeatId: (seatId: number) => NormalizedTicket,
    getTicketItemByUuid: (ticketItemUuid: string) => NormalizedTicketItem
  ): TicketStatus[] {
    const ticket_items = _.flatMap(
      _.map(seats, (seat) => getTicketBySeatId(seat.id)?.ticket_items)
    ).filter((i) => !!i && !getTicketItemByUuid(i).parent_uuid);
    if (ticket_items?.length <= 0) {
      return isCoursingEnabled
        ? [
            TicketStatus.EMPTY,
            TicketStatus.EMPTY,
            TicketStatus.EMPTY,
            TicketStatus.EMPTY
          ]
        : [TicketStatus.EMPTY];
    }

    const courseStatuses: TicketStatus[] = [];
    const courses = isCoursingEnabled ? CommonConstant.ALL_COURSES : [null];
    for (const course of courses) {
      const ticketItemsByCourse =
        ticket_items
          .map((i) => getTicketItemByUuid(i))
          .filter((i) =>
            // If course is 0 or null, we need to check if the ticket item is null or 0
            // this must be doing like this because we have all data and something disabled coursing ticket item returns 0 instead of null.
            course === 0 || course === null
              ? i.course === null || i.course === 0
              : i.course === course
          ) || [];
      let status = TicketStatus.EMPTY;
      if (ticketItemsByCourse.length) {
        const isAllFired = ticketItemsByCourse.every(
          (i) => i?.status === TicketItemStatus.FIRED
        );
        status = isAllFired ? TicketStatus.FIRED : TicketStatus.IN_PROGRESS;
      }
      courseStatuses.push(status);
    }
    return courseStatuses;
  }

  static seatsStatues(
    seats: Seat[],
    isCoursingEnabled: boolean,
    getTicketBySeatId: (seatId: number) => NormalizedTicket,
    getTicketItemByUuid: (ticketItemUuid: string) => NormalizedTicketItem
  ) {
    const seatStatuses: TicketStatus[][] = [];
    for (const seat of seats) {
      const courseStatuses: TicketStatus[] = TableHelper.courseStatuses(
        [seat],
        isCoursingEnabled,
        getTicketBySeatId,
        getTicketItemByUuid
      );
      seatStatuses[seat.number] = courseStatuses;
    }
    return seatStatuses;
  }

  // convert course index to number in the courseStatuses list.
  static covertCourseIndexToNumber(index: number) {
    return index === 3 ? 0 : index + 1;
  }

  static isFloorPlanTableChanged(
    newTable: Table,
    oldTable: Table,
    getTableStatus?: (table: Table) => TableListingStatus
  ): boolean {
    if (!newTable || !oldTable) {
      // although it's rarely to jump in this condition, I put it here to prevent the null pointer for the below code.
      return true;
    }

    const tableProperties = ['assigned_staff_name', 'tableshape'];
    const seatProperties = [
      'device_ticket_uuid',
      'align',
      'number',
      'focus',
      'ticket_status',
      'floorplan_status'
    ];

    let isChanged = false;
    if (tableProperties.every((prop) => oldTable[prop] === newTable[prop])) {
      if (
        oldTable.pending_seat_assignments?.length !==
        newTable.pending_seat_assignments?.length
      ) {
        // pending seat assignment changes to the color
        return true;
      }

      const oldTableSeats = oldTable.seats;
      const newTableSeats = newTable.seats;

      if (oldTableSeats && newTableSeats) {
        if (newTableSeats.length !== oldTableSeats.length) {
          isChanged = true;
        } else if (
          oldTableSeats.length &&
          newTableSeats.length &&
          oldTableSeats.length === newTableSeats.length
        ) {
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let i = 0; i < oldTableSeats.length; i++) {
            const oldSeat = oldTableSeats[i];
            if (!oldSeat || !oldSeat.id) {
              /** The oldSeat may be undefined, incase this is the temporary seat add it has not yet assigned to the guest or diner.
               * Sometimes, it's in the middle of updating process. */
              continue;
            }
            const newSeat = newTableSeats.find(
              (x) => x?.id && x.id === oldSeat.id
            );
            if (!newSeat || !newSeat.id) {
              // like the old seat, new seat may be jump to this situation
              isChanged = true;
              break;
            }
            const match = seatProperties.every(
              (prop) => oldSeat[prop] === newSeat[prop]
            );
            if (!match) {
              // any child seat doesn't match => is changed is true
              isChanged = true;
              break;
            }
          }
        }
      }
    } else {
      // if any properties of parent table doesn't match => is changed is true
      isChanged = true;
    }

    if (
      oldTable.local_attributes?.hostModeStatus !==
      newTable.local_attributes?.hostModeStatus
    ) {
      isChanged = true;
    }

    if (
      !isChanged &&
      getTableStatus &&
      getTableStatus(newTable) !== getTableStatus(oldTable)
    ) {
      isChanged = true;
    }

    return isChanged;
  }

  static insertReservedTableStatus(
    tables: Table[],
    reservedTables: ReservationTable[],
    reservationSettings: ReservationSettings
  ) {
    try {
      tables
        .filter(
          (table) =>
            table.local_attributes.status_name ===
            TableConstant.TABLE_STATUS.EMPTY_TABLE
        )
        .forEach((table) => {
          const nearestReservedTable: ReservationTable = reservedTables.find(
            (reservedTable) => {
              const currentDateTime = new Date();

              const currentTime: PosTime = {
                hour: currentDateTime.getHours(),
                minute: currentDateTime.getMinutes()
              };

              const currentDate = DateHelper.format(currentDateTime);

              const reservationTimeRange: TimeRange = {
                start: TimeHelper.getPosTime(
                  reservedTable.local_attrs.start_time
                ),
                end: TimeHelper.getPosTime(reservedTable.local_attrs.end_time)
              };

              if (table.id !== reservedTable.table_id) {
                return false;
              }

              if (currentDate !== reservedTable.local_attrs.date) {
                return false;
              }

              return TimeHelper.isTimeInRange(
                currentTime,
                reservationSettings?.auto_block_table_after
                  ? {
                      ...reservationTimeRange,
                      start: TimeHelper.minusByMinute(
                        reservationTimeRange.start,
                        reservationSettings.auto_block_table_after
                      )
                    }
                  : reservationTimeRange
              );
            }
          );

          if (!!nearestReservedTable) {
            const startTime = TimeHelper.getPosTime(
              nearestReservedTable.local_attrs.start_time
            );
            const formattedStartTime = TimeHelper.getTimeWithMarker(
              startTime.hour,
              startTime.minute,
              true
            );

            TableHelper.modifyingReservedTable(
              table,
              `Reserved for ${formattedStartTime}`
            );
          }
        });

      return tables;
    } catch (ex) {
      return tables;
    }
  }

  static modifyTable(
    modifyingTable: Table,
    pendingAssignments?: PendingAssignment[]
  ): Table {
    try {
      const assignedSeatNumber = modifyingTable.seats?.length
        ? modifyingTable.seats.filter((seat) => !!seat.device_ticket_uuid)
            .length
        : 0;
      modifyingTable.totalAssignedSeatNumber =
        assignedSeatNumber +
        (pendingAssignments?.filter((i) => i.table_id === modifyingTable.id)
          .length || 0);

      if (modifyingTable.local_attributes.status_class === 'reserved') {
        return modifyingTable;
      }

      const occupiedTotal = _.filter(
        modifyingTable.seats,
        (s) => s?.device_ticket_uuid
      ).length;
      const assignedTotal = modifyingTable.pending_seat_assignments.length;
      let hostModeStatus: HostModeStatus = null;
      if (occupiedTotal > 0) {
        hostModeStatus = HostModeStatus.OCCUPIED;
      } else if (assignedTotal > 0) {
        hostModeStatus = HostModeStatus.ASSIGNED;
      } else {
        hostModeStatus = HostModeStatus.AVAILABLE;
      }

      modifyingTable.local_attributes = {
        ...modifyingTable.local_attributes,
        hostModeStatus
      };
      return modifyingTable;
    } catch (ex) {
      return modifyingTable;
    }
  }

  static modifyingReservedTable(table: Table, statusName?: string): Table {
    table.local_attributes = {
      ...table.local_attributes,
      hostModeStatus: HostModeStatus.RESERVED,
      status_name: statusName || 'Reserved',
      status_class: 'reserved'
    };
    table.seats.forEach((seat) => {
      seat.local_attributes = seat.local_attributes
        ? { ...seat.local_attributes, isReserved: true }
        : { isReserved: true };
    });

    return table;
  }

  static modifyingSelectedTable(table: Table, statusName?: string) {
    table.local_attributes = {
      ...table.local_attributes,
      hostModeStatus: HostModeStatus.SELECTED,
      status_name: statusName || 'Selected',
      status_class: 'selected'
    };
    table.seats.forEach((seat) => {
      seat.local_attributes = seat.local_attributes
        ? { ...seat.local_attributes, isTableSelected: true }
        : { isTableSelected: true };
    });

    return table;
  }

  static getFireButtonKey(
    type: 'course' | 'seat' | 'course-seat',
    value: {
      course?: number;
      seat?: number;
    }
  ) {
    const { course, seat } = value;
    switch (type) {
      case 'course':
        return `course-${course}`;
      case 'seat':
        return `seat-${seat}`;
      case 'course-seat':
        return `course-${course}-seat-${seat}`;
    }
  }

  static getCurrentReservationOfTable(
    reservations: (TableReservation | FloorPlanTableReservation)[],
    reservationMealConfigurations: ReservationMealConfiguration[]
  ): TableReservation | FloorPlanTableReservation {
    if (!reservationMealConfigurations?.length) {
      return null;
    }

    return _.find(reservations, (r) => {
      const start = TimeHelper.getPosTime(r.start_time);
      const { time_to_block_table_before_reservation } =
        reservationMealConfigurations.find((i) => i.meal_id === r.meal_id);

      return TableHelper.isCurrentReservation(
        time_to_block_table_before_reservation,
        start,
        r
      );
    });
  }

  private static isCurrentReservation(
    time_to_block_table_before_reservation: number,
    start: PosTime,
    r: TableReservation | FloorPlanTableReservation
  ) {
    const currentDateTime = new Date();

    if (time_to_block_table_before_reservation) {
      if (start.minute >= time_to_block_table_before_reservation) {
        start.minute = start.minute - time_to_block_table_before_reservation;
      } else {
        start.hour = start.hour - 1;
        start.minute =
          60 - (time_to_block_table_before_reservation - start.minute);
      }
    }

    return TimeHelper.isTimeInRange(
      {
        hour: currentDateTime.getHours(),
        minute: currentDateTime.getMinutes()
      },
      {
        start,
        end: TimeHelper.getPosTime(r.end_time)
      }
    );
  }

  static getTableBySeat(seatId: number, tables: Table[]): Table {
    return tables.find((table) =>
      table.seats.some((seat) => seat.id === seatId)
    );
  }

  static getTableStatusName(
    tableStatus: TableListingStatus,
    reservations: TableReservation[],
    reservationConfigurations: ReservationMealConfiguration[],
    datePipe: DatePipe,
    isCoursingEnabled: boolean
  ) {
    switch (tableStatus) {
      case TableListingStatus.Reserved:
        const startTime = TableHelper.getCurrentReservationOfTable(
          reservations || [],
          reservationConfigurations
        ).start_time;
        const formattedStartTime = TimeHelper.getPosTime(startTime);
        const time = new Date().setHours(
          formattedStartTime.hour,
          formattedStartTime.minute,
          0,
          0
        );
        return 'Reserved for ' + datePipe.transform(time, 'h:mm a');
      case TableListingStatus.Available:
        return tableStatus;
      case TableListingStatus.NoOrdersTaken:
        return 'No orders taken';
      case TableListingStatus.Pending:
        return isCoursingEnabled ? 'Pending course' : 'Pending item';
      case TableListingStatus.Fired:
        return isCoursingEnabled ? 'All courses fired' : 'All items fired';
    }
  }

  static geTableSeatedTime(table: Table): string {
    const getTimeLeft = (firstTime: string) => {
      const value = moment(new Date(firstTime)).toString();
      const t = Date.parse(new Date().toString()) - Date.parse(value);
      const minutes = Math.floor((t / 1000 / 60) % 60);
      const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      const days = Math.floor(t / (1000 * 60 * 60 * 24));
      return { days, hours, minutes };
    };

    if (_.every(table.seats, (s) => !s.device_ticket_uuid)) {
      return 'N/A';
    }

    const tickets = Array.from(_.get(table, 'seats', []))
      .map((s: Seat) => s?.ticket_created_at)
      .filter((i) => !!i)
      .sort();
    const firstTicket = tickets?.length && tickets[0];
    if (firstTicket) {
      const { days, hours, minutes } = getTimeLeft(firstTicket);
      return `${days > 0 ? days + 'day' : ''} ${hours}h ${minutes}m`;
    }
    return 'N/A';
  }

  static formatTableWithValidSeat(table: Table): Table {
    return {
      ...table,
      seats: table.seats.filter(
        (s) => s?.align && s.focus && !s.is_temporary_seat
      )
    };
  }

  static formatFloorplanTable(t: Table): PosFloorplanTable {
    const tableSeats = t.seats;
    const floorplanTable: PosFloorplanTable = {
      totalSeatsNo: tableSeats.length,
      occupiedSeatsNo: tableSeats.filter((s) => !!s.device_ticket_uuid).length,
      name: t.name,
      id: t.id,
      status: t.status,
      tableshape: t.tableshape,
      location: t.location,
      top: t.top,
      left: t.left,
      right: t.right,
      bottom: t.bottom,
      pendingAssignment: !!t.pending_seat_assignments.length,
      assigned_staff_name: t.assigned_staff_name,
      assigned_staff_id: t.assigned_staff_id,
      seats: t.seats.map((s) => {
        const floorplanSeat: PosFloorplanSeat = {
          id: s.id,
          number: s.number,
          floorplan_status: s.floorplan_status,
          align: s.align,
          focus: s.focus
        };
        return floorplanSeat;
      })
    };
    return floorplanTable;
  }

  static filterTablesByPermission<T>(
    tables: (T & { assigned_staff_id?: number })[],
    operator: Operator
  ): T[] {
    const isTableFiltered =
      !operator.permissions.assign_tables &&
      tables.some((t) => t.assigned_staff_id === operator.id);

    return isTableFiltered
      ? tables.filter((t) => t.assigned_staff_id === operator.id)
      : tables;
  }

  private static calculateTimeSeated(
    minutesFromNow: number,
    hours: number
  ): string {
    let timeSeated = 'N/A';

    const isOverMaxHours =
      minutesFromNow * TimeDuration.Minute >= MAX_HOUR * TimeDuration.Hour;
    if (isOverMaxHours) {
      timeSeated = MAX_HOUR + 'h+';
    } else {
      const isMoreThanHour = hours > 0;
      timeSeated = isMoreThanHour
        ? hours + 'h' + (minutesFromNow % 60) + 'm'
        : minutesFromNow + 'm';
    }

    return timeSeated;
  }

  static formatTablesWithMaxSeatedTime({
    seats,
    status,
    reservations,
    name,
    id
  }: Table): PosListingTable {
    let reservedTime: number = null;

    // only reserved table has reserved time
    if (status === PosTableStatus.Reserved && reservations?.length) {
      const activeReservation = _.find(reservations, (r) => {
        if ('time_to_block_table_before_reservation' in r) {
          const start = TimeHelper.getPosTime(r.start_time);

          return TableHelper.isCurrentReservation(
            r.time_to_block_table_before_reservation,
            start,
            r
          );
        }
        return false;
      });

      if (activeReservation) {
        const formattedStartTime = TimeHelper.getPosTime(
          activeReservation.start_time
        );
        reservedTime = new Date().setHours(
          formattedStartTime.hour,
          formattedStartTime.minute,
          0,
          0
        );
      }
    }

    const occupiedSeatsNo = seats.filter((s) => !!s.device_ticket_uuid).length;
    const hasAtLeastOneSeated = occupiedSeatsNo > 0;
    let timeSeated = 'N/A';
    if (hasAtLeastOneSeated) {
      const maxSeatedTime = _.max(
        seats.map((s) =>
          s.ticket_created_at ? new Date(s.ticket_created_at).getTime() : 0
        )
      );
      const minutesFromNow = maxSeatedTime
        ? differenceInMinutes(new Date(), new Date(maxSeatedTime))
        : 0;

      timeSeated = TableHelper.calculateTimeSeated(
        minutesFromNow,
        minutesToHours(minutesFromNow)
      );
    }

    const tableWithMaxSeatedTime: PosListingTable = {
      reservedTime,
      timeSeated,
      name,
      id,
      status,
      occupiedSeatsNo,
      totalSeatsNo: seats.length,
      isOverMaxHours: timeSeated ? timeSeated === MAX_HOUR + 'h+' : false
    };

    return tableWithMaxSeatedTime;
  }

  static updateTableListing(
    tables: Table[],
    operator?: Operator
  ): PosListingTable[] {
    return TableHelper.formatTableWithMaxSeatedTime(
      operator ? TableHelper.filterTablesByPermission(tables, operator) : tables
    );
  }

  private static formatTableWithMaxSeatedTime(
    tables: Table[]
  ): PosListingTable[] {
    return tables.map((t) => TableHelper.formatTablesWithMaxSeatedTime(t));
  }

  static filterValidSeatsForTablesManagement(tables: Table[]): Table[] {
    return produce(tables, (draft) => {
      draft.forEach(
        (draftTable) =>
          (draftTable.seats = draftTable.seats.filter(
            (s) => s?.align && s.focus && !s.is_temporary_seat
          ))
      );
    });
  }

  private static filterValidSeats(
    tablesState: NormalizedObject<NormalizedTable, number>,
    normalizedSeats: { [id: number]: NormalizedSeat },
    filter = false
  ): Table[] {
    if (tablesState.byId == null) {
      return null;
    }

    const entities: NormalizedEntities = {
      tables: tablesState.byId,
      seats: filter
        ? _.pickBy(
            normalizedSeats,
            (s) => s.align && s.focus && !s.is_temporary_seat
          )
        : normalizedSeats
    };
    let tables: Table[] = _.orderBy(
      denormalize(tablesState.allIds, tablesArraySchema, entities) || [],
      'sort'
    );
    tables = produce(tables, (draft) => {
      draft.forEach(
        (draftTable) => (draftTable.seats = _.compact(draftTable.seats))
      );
    });
    return tables;
  }

  static filterValidSeatsByPermission(
    tablesState: NormalizedObject<NormalizedTable, number>,
    normalizedSeats: _.NumericDictionary<NormalizedSeat>,
    operator: Operator,
    filter = false
  ): Table[] {
    const tables = this.filterValidSeats(tablesState, normalizedSeats, filter);
    return TableHelper.filterTablesByPermission(tables, operator) as Table[];
  }
}
