import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { POSFullMenu, POSMenuRequest } from 'src/app/models/pos-manager.model';
import { ErrorService } from 'src/app/services/error/error.service';
import { AppStateHelper } from 'src/app/store/app/app.state.helper';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';

@Injectable({ providedIn: 'root' })
export class PosMenuService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private readonly errorService: ErrorService
  ) {}

  private get getBaseUrl(): string {
    const baseUrl = this.store.selectSnapshot(APP_STATE_TOKEN).apiBaseUrl;
    const posManagerApiUrl = AppStateHelper.getPosManagerApiUrl(baseUrl);

    return `${posManagerApiUrl}menu_addons`;
  }

  getMenus(request: POSMenuRequest): Observable<POSFullMenu> {
    if (!request) {
      throw new Error('Menu request can not be empty');
    }
    const { date, pos_location_id, meal_id, include_all_items } = request;
    const newRequest = meal_id
      ? request
      : { date, pos_location_id, include_all_items };
    return this.http
      .get<POSFullMenu>(this.getBaseUrl, {
        params: { ...newRequest }
      })
      .pipe(
        this.errorService.retryPipe(),
        map((response) => ({
          ...response,
          menu_items: response.menu_items.map((item) => ({
            ...item,
            locationId: pos_location_id
          })),
          also_available_menu_items: response.also_available_menu_items.map(
            (item) => ({
              ...item,
              locationId: pos_location_id
            })
          ),
          products: response.products.map((item) => ({
            ...item,
            locationId: pos_location_id
          }))
        }))
      );
  }
}
