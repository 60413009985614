import { HttpStatusCode } from '@angular/common/http';
import _ from 'lodash';

export enum CustomErrorCode {
  DeviceOffline = 'DEVICE_OFFLINE',
  NoNetwork = 'NO_NETWORK_ERROR',
  UnknownError = 'UNKNOWN_ERROR',
  RequestTimeout = 'REQUEST_TIMEOUT_ERROR',
  HttpNoResponseError = 0
}

const ConnectionErrorMessage =
  'The device does not have internet connectivity or it’s too slow. Click RETRY. If you are still unable to connect, please contact your IT Department for assistance.';

const CustomErrorMessageByCode: { [key in CustomErrorCode]: string } = {
  [CustomErrorCode.DeviceOffline]:
    'The device does not have internet connectivity',
  [CustomErrorCode.HttpNoResponseError]: ConnectionErrorMessage,
  [CustomErrorCode.RequestTimeout]: ConnectionErrorMessage,
  [CustomErrorCode.NoNetwork]: ConnectionErrorMessage,
  [CustomErrorCode.UnknownError]:
    'Unknown error. Please contact your IT Department for assistance.'
};

const HttpResponseMessageByCode: { [key: string]: string } = {
  /** Http Client Error */
  [HttpStatusCode.BadRequest]:
    'There was a problem from the server. Please try again or reach out to support.',
  [HttpStatusCode.Unauthorized]: 'Unauthorized',
  [HttpStatusCode.Forbidden]: 'Forbidden Error',
  [HttpStatusCode.NotFound]: 'NotFound Error',
  /** Http Server Error */
  [HttpStatusCode.InternalServerError]:
    'There was a problem from the server. Please try again or reach out to support.',
  [HttpStatusCode.BadGateway]:
    'There was a problem from the server. Please try again or reach out to support.'
};

export class ErrorConstant {
  static readonly ErrorMessageByCode = _.merge(
    CustomErrorMessageByCode,
    HttpResponseMessageByCode
  );

  //#region Login

  static readonly LOGIN_PAGE_ERROR_CODE = {
    LP_000001: 'LP_000001'
  };

  static readonly LOGIN_PAGE_ERROR: { [key: string]: string } = {
    LP_000001: 'Authenticate API Failed.'
  };

  static readonly EmptyMacroGridsErrorMessage = `<p>Your menu could not be loaded because a POS Grid is not assigned to this location.</p>
    <p>Please contact your Food Service Manager, or MealSuite Support for assistance.</p>
    `;

  static readonly CUSTOM_RETRY_ERROR_MSG = {
    GET_MENU_TALLY:
      'Failed to load menu. Please check your network connection and try again.'
  };
  //#endregion
}
