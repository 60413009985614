export class PagePath {
  static readonly DINING_ROOM_PAGE = 'dining-room';
  static readonly FLOOR_PLAN_PAGE = 'floor-plan';
  static readonly HOST_MODE_FLOOR_PLAN_PAGE = 'host-mode';
  static readonly TAKEOUT_DELIVERY_PAGE = 'takeout-delivery';
  static readonly RESERVATION = 'reservation';
  static readonly RESERVATION_NEW = 'new';
}

export class PageConstant {
  //Common Pages
  static readonly DEVICE_REGISTRATION_PAGE = 'device-registration';
  static readonly LOGIN_PAGE = 'login';
  static readonly SUPPORT_PAGE = 'support';
  static readonly PAYMENTS_PAGE = 'payments';
  static readonly TRANSACTIONS_PAGE = 'transactions';
  //Dining Room pages
  static readonly DINING_ROOM_PAGE = `${PagePath.DINING_ROOM_PAGE}`;
  static readonly DINING_FLOORPLAN_PAGE = `${PagePath.DINING_ROOM_PAGE}/${PagePath.FLOOR_PLAN_PAGE}`;
  static readonly DINING_TAKEOUT_PAGE = `${PagePath.DINING_ROOM_PAGE}/${PagePath.TAKEOUT_DELIVERY_PAGE}`;
  static readonly CHECKOUT_PAGE = 'checkout';
  static readonly TABLES_ASSIGNMENT_PAGE = 'tables-assignment';

  //Host Mode
  static readonly DINING_HOST_MODE_PAGE = `${PagePath.DINING_ROOM_PAGE}/${PagePath.HOST_MODE_FLOOR_PLAN_PAGE}`;
  static readonly HOST_MODE_RESERVATION = `${PageConstant.DINING_HOST_MODE_PAGE}/${PagePath.RESERVATION}`;
  static readonly HOST_MODE_RESERVATION_NEW = `${PageConstant.HOST_MODE_RESERVATION}/${PagePath.RESERVATION_NEW}`;
  //Quick Service pages
  static readonly QUICK_SERVICE_PAGE = 'quick-service';
  static readonly ON_HOLD_PAGE = 'on-hold';
  //Self Service
  static readonly SELF_SERVICE_SETUP_PAGE = 'self-service/setup';
  static readonly SELF_SERVICE_ORDERING_PAGE = 'self-service/ordering';
  static readonly SELF_SERVICE_PAYMENT_PAGE = 'self-service/payment';
  //POS Manager
  static readonly POS_MANAGER_PAGE = 'pos-manager';
  static readonly POS_MANAGER_MODIFIER_PROMPTS_PAGE = `${PageConstant.POS_MANAGER_PAGE}/modifier-prompts`;
  static readonly POS_MANAGER_POS_ITEMS_MANAGEMENT_PAGE = `${PageConstant.POS_MANAGER_PAGE}/pos-items-management`;
  static readonly POS_MANAGER_TABLES_MANAGEMENT_PAGE = `${PageConstant.POS_MANAGER_PAGE}/tables-management`;
  static readonly POS_MANAGER_POS_OPERATORS_MANAGEMENT_PAGE = `${PageConstant.POS_MANAGER_PAGE}/pos-operators-management`;
  static readonly POS_MANAGER_SCHEDULES_SERVICES_PAGE = `${PageConstant.POS_MANAGER_PAGE}/schedules-services`;
  static readonly POS_MANAGER_REPORTING_PAGE = `${PageConstant.POS_MANAGER_PAGE}/reporting`;

  static readonly POS_MANAGER_PRODUCT_EDIT_PAGE = `${PageConstant.POS_MANAGER_PAGE}/product-edit`;

  static readonly TAKEOUT_DELIVERY_PAGE = (isDiningRoom: boolean) =>
    isDiningRoom
      ? PageConstant.DINING_TAKEOUT_PAGE
      : PagePath.TAKEOUT_DELIVERY_PAGE;
  static readonly TAKEOUT_DELIVERY_ORDER_PAGE = `${PagePath.TAKEOUT_DELIVERY_PAGE}/order`;
  static readonly TAKEOUT_DELIVERY_NEW_ORDER_PAGE = `${PageConstant.TAKEOUT_DELIVERY_ORDER_PAGE}/new`;
  static readonly TAKEOUT_DELIVERY_VIEW_ORDER_PAGE = `${PageConstant.TAKEOUT_DELIVERY_ORDER_PAGE}/detail`;
  //dev
  static readonly HARDWARE_DEV_TEST = 'hardware-dev-test';

  //Services Location
  static readonly SERVICES_BOOKING_PAGE = 'services/booking';
  static readonly SERVICES_APPOINTMENTS_PAGE = 'services/appointments';
  static readonly SERVICES_SEARCH_APPOINTMENTS_PAGE =
    'services/search-appointments';
  static readonly SERVICES_SHOP_PAGE = 'services/shop';

  //Census Report
  static readonly CENSUS_REPORT = 'census-report';
}
