import { TimeDuration } from 'src/app/models';

export enum ApiType {
  Mutate = 'mutate',
  Query = 'query',
  MenuTallyLoading = 'menuTallyLoading'
}

export class PosTimeoutConfig {
  private static readonly POS_QUERY_API_TIMEOUT_TIME = 10 * TimeDuration.Hour;
  private static readonly POS_MUTATE_API_TIMEOUT_TIME = 20 * TimeDuration.Hour;

  static readonly POS_TIMEOUT_TIME_CONST: Record<ApiType, number> = {
    [ApiType.Mutate]: PosTimeoutConfig.POS_MUTATE_API_TIMEOUT_TIME,
    [ApiType.Query]: PosTimeoutConfig.POS_QUERY_API_TIMEOUT_TIME,
    [ApiType.MenuTallyLoading]: TimeDuration.Minute
  };
}

export class PosConfig {
  static readonly POS_API_TIMEOUT_TIME = (type: ApiType) =>
    PosTimeoutConfig.POS_TIMEOUT_TIME_CONST[type];
}
